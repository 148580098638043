<template>
  <!--有黄色加号-->
  <div class="detail-box">
    <div class="left">
      <img class="full-img" :src="goods.default_image">
    </div>
    <div class="right">
      
      <div class="title-box">
        <div class="title1">{{goods.goods_name}}</div>
        <div class="title2">{{goods.brand_name}}</div>
      </div>
      <div class="price-box flexcenter">
        <div class="huiyuan">
          <span class="hy1">￥{{goods.price}}</span>
          <span class="hy2">心选价</span>
        </div>
      </div>
  
      <div class="price-box flexcenter">
        <div class="price">￥{{goods.marking_price}}</div>
      </div>
      
      <div class="desc-box flexcenter">
        <div>已售{{goods.sale_number}}件</div>
		<div v-if="goods.have_sale_attr">
			<i v-if="showAddbtn" class="iconfont guige2 add" @click.stop="addToCart(goods.goods_id)">规格</i>
			<i v-if="goods.cart_number > '0'" class="num_tags">{{goods.cart_number}}</i>
	    </div>
	    <div v-else>
			<i v-if="goods.cart_number" class="iconfont iconjian2" @click.stop="reduceCart(goods.goods_id)"></i>
			<i v-if="goods.cart_number" class="cartNumber">{{goods.cart_number}}</i>
			<i v-if="showAddbtn" class="iconfont iconjia2" @click.stop="addToCart(goods.goods_id)"></i>
	    </div>
        
      </div>
    
    </div>
  </div>

</template>

<script>
  export default {
    name: 'product2',
    props: {
      goods:Object,
      showAddbtn:Boolean,
      isShowChose: {
        type: Boolean,
        default: false,
      },
      bordernone: {
        type: Boolean,
      }
    },
    data() {
      return {
        value: 1,
      }
    },
    methods: {
      onChange(event) {
        console.log(event.detail);
      },
      addToCart:function(e){
        this.$emit("addCart",e);
      },
      reduceCart:function(e){
        this.$emit("reduceToCart",e)
      }
    }
  }
</script>

<style scoped>
  .full-img {
    display: block;
    width: 100%;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  .guige2 {
      font-size: 24px;
      color: #FFFFFF;
      background: #FEA30F;
      padding: 8px 14px;
      border-radius: 6px;
  }
  .num_tags {
      width: 36px;
      height: 36px;
      background: #FF0000;
      display: block;
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      color: #FFFFFF;
      border-radius: 36px;
      position: absolute;
      margin-left: 56px;
      margin-top: -56px;
      font-size: 20px;
  }
  .detail-box {
    display: flex;
	line-height: 30px;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    background-color: #FFFFFF;
  }
  .detail-box .left {
    margin-right: 20px;
    width: 200px;
  }
  .detail-box .right {
    flex: 1;
  }
  .detail-box .right .title-box {
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  .detail-box .right .title-box .title1 {
    padding-top: 10px;
  }
  .detail-box .right .title-box .title2 {
    font-size: 24px;
    font-weight: normal;
    color: #777777;
  }
  .detail-box .right .price-box .price {
    margin-right: 16px;
    font-size: 30px;
    font-weight: bold;
    color: #F84F41;
    text-indent: -4px;
  }
  .detail-box .right .price-box .huiyuan {
    text-align: center;
    font-size: 24px;
  }
  .detail-box .right .price-box .huiyuan .hy1 {
    display: inline-block;
    width: 92px;
    height: 30px;
    line-height: 30px;
    background-image: linear-gradient(106deg, #464E51 0%, #454D51 100%);
    border-radius: 4px 0 0 4px;
    color: #E4DCAD;
  }
  .detail-box .right .price-box .huiyuan .hy2 {
    display: inline-block;
    width: 92px;
    height: 28px;
    line-height: 28px;
    background-image: linear-gradient(48deg, #FCE7BD 0%, #FFF6D4 100%);
    border-radius: 0 4px 4px 0;
    color: #4C462C;
  }
  .detail-box .right .desc-box {
    justify-content: space-between;
    font-size: 24px;
    margin: 16px 0;
    color: #777777;
  }
  .iconjia2 ,.iconjian2{
	  font-size: 36px;
	  font-style: normal;
  }
  .cartNumber {
      display: inline-block;
      width: 60px;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
	  font-style: normal;
  }
</style>
