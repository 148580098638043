<template>
<!--商品sku选择器-->
<van-action-sheet
    z-index="300"
    v-model="skuPopShow2"
>
  <div class="skuPopBox">
    <product2
        :goods="skuPopGoods"
        :number.sync="skuPopGoods.goods_number"
    ></product2>
    <!--skuItem-->
    <div class="sku-item-box"
          v-for="(saleAttr, sale_attr_index) in skuPopGoods.sale_attr_head"
          :key="saleAttr.index"
    >
      <div class="sku-item-name">{{saleAttr.attr_name}}</div>
      <div class="sku-item-value-box">
        <van-button plain size="small"
                    :class="['sku-item', {'sku-active': goods_attr_idChosen === item.goods_attr_id}]"
                    :disabled = "item.disabled"
                    v-for="(item, goods_attr_index) in saleAttr.items"
                    :key="item.goods_attr_id"
                    @click="selectSaleAttr(item,sale_attr_index)"
        >
          {{item.attr_value}}
        </van-button>
      </div>
    </div>
    <!--endSkuItem-->
    <van-cell title="购买数量">
      <van-stepper slot="right-icon" v-model="number" min="1" @change="changeNumber"/>
    </van-cell>
    <van-goods-action class="sku-pop-btn-box">
      <van-goods-action-button
          text="加入购物车"
          color="#1D262E"
          data-type="normal"
          @click="addToCart"
      />
      <van-goods-action-button
          text="立即购买"
          color="#FCC601"
          data-type="quick"
          @click="quickToBuy"
      />
    </van-goods-action>
  </div>
</van-action-sheet>
</template>

<script>
    import product2 from 'components/product2'
	import Vue from 'vue';
	import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';

	Vue.use(GoodsAction);
	Vue.use(GoodsActionButton);
	Vue.use(GoodsActionIcon);
  export default {
    name: 'product-sku-popup',
    props: {
      skuPopShow: {
        type: Boolean,
        value: false,
      },
      skuPopTitle :{
        type:String,
        value:'请选择商品规格'
      },
      skuPopGoods :{
        type:Object,
        value:{}
      }
    },
    data() {
      return {
        number:'1',
        goods_attr_idChosen: ''
      }
    },
	components: {
	  product2,
	},
	computed: {
	    skuPopShow2: {
			get() {
			  return this.skuPopShow;
			},
			set(val) {
			  //grants_改变由父组件控制
			  this.$emit("closeSkuPop", val);
			}
		},
		skuPopGoods2:{
			get() {
			  return this.skuPopGoods;
			},
			set(val) {
			  //grants_改变由父组件控制
			  this.$emit("selectSaleAttr", val);
			}
		}
	},
    methods: {
      //修改数量
      changeNumber(e){
        this.$emit("updateSelectedSku",{
          sku_id:this.selectedSkuId,
          sku_name:this.selectedSkuName,
          number:this.number
        })
      },
      //加入购物车
      addToCart(e){
        this.$emit("addToCartLocal",{
          sku_id:this.selectedSkuId,
          sku_name:this.selectedSkuName,
          number:this.number
        })
      },
      //直接购买
      quickToBuy(e){
		  console.log(this.selectedSkuId)
        this.$emit("quickToBuy",101);
      },
      //选择规格
      selectSaleAttr(e,index){
        let attr_sn = e.goods_attr_sn;
        let attr_id = e.attr_id;
        let goods_attr_id = e.goods_attr_id;
        let sale_attr_index = index;
        let is_remove_selected = 0;

        let skuPopGoods = this.skuPopGoods2;
        let that = this;
        let selectedAttrSn = [];

        this.selectedSkuId=0
		
        skuPopGoods.sale_attr_head.forEach(function(sale_attr,key) {
          sale_attr.items.forEach(function(attr,k){
            if(attr.attr_id==attr_id)
            {
              if(attr.selected==0||typeof attr.selected == "undefined")
              {
                attr.selected = 0;
                attr.selectedStatus ="";
                if(attr.goods_attr_id==goods_attr_id&&attr.goods_attr_sn==attr_sn)
                {
					        console.log("选中了我")
                  attr.selected = 1;
                  attr.selectedStatus ="sku-active";
                  that.goods_attr_idChosen = attr.goods_attr_id
				          skuPopGoods.sale_attr_head[key]['items'][k] = attr;
                }
              }
              else
              {
                attr.selected = 0;
                attr.selectedStatus ="";
                skuPopGoods.sale_attr_head[key]['items'][k] = attr;
                is_remove_selected = 1;
              }

            }
          })
        });

        skuPopGoods.sale_attr_head.forEach(function(sale_attr,key) {
          skuPopGoods.sale_attr_head[key]['selected'] = 0;
          sale_attr.items.forEach(function(attr,k){
            if(attr.selected == 1)
            {
              skuPopGoods.sale_attr_head[key]['selected'] = 1;
              selectedAttrSn.push(attr.goods_attr_sn);
            }
          })
        });

        let all_ids = this._filterAttrs(selectedAttrSn);

        //设置不可点击
        skuPopGoods.sale_attr_head.forEach(function(sale_attr,key) {
          if(is_remove_selected==1)
          {
            sale_attr.items.forEach(function(attr,k){
              attr.disabled = false;
              console.log("移除一个规格");
              console.log(attr.goods_attr_sn);
              if(!all_ids.includes(attr.goods_attr_sn))
              {
                //attr.disabled = true;
              }

              skuPopGoods.sale_attr_head[key]['items'][k] = attr;
            })
          }
          else if(key!=sale_attr_index)
          {
            sale_attr.items.forEach(function(attr,k){
              attr.disabled = false;
              console.log(all_ids);
              //console.log(attr.goods_attr_sn);
              if(!all_ids.includes(attr.goods_attr_sn))
              {
                attr.disabled = true;
              }

              skuPopGoods.sale_attr_head[key]['items'][k] = attr;
            })
          }
        });

        //获取选择的最终 sku_id
        console.log("all_ids.length="+all_ids.length);
        console.log("skuPopGoods.sale_attr_head="+skuPopGoods.sale_attr_head.length);
        let _selectedSkuId = 0;
        let _selectedSkuName = '';
        if(all_ids.length==skuPopGoods.sale_attr_head.length)
        {
          skuPopGoods.sku_list.forEach(function(sku,v){
            let goods_attr_sn = sku['sku_attr_ids'].split(":");
            if(all_ids.sort().toString()==goods_attr_sn.sort().toString())
            {
              _selectedSkuId = sku['sku_id'];
              _selectedSkuName = sku['sku_goods_name'];
              console.log("最终选择的SKUID是："+ _selectedSkuId);
            }
          });
        }
		
		console.log(skuPopGoods)

        this.skuPopGoods=skuPopGoods
        this.selectedAttrSn=selectedAttrSn
        this.selectedSkuId=_selectedSkuId
        this.selectedSkuName=_selectedSkuName

        this.$emit("updateSelectedSku",{
          sku_id:this.selectedSkuId,
          sku_name:this.selectedSkuName,
          number:this.number
        })
      },
      /**
       * SKU选择器
       * 获取所有包含指定节点的路线
       */
      _filterProduct(ids) {
        let sku_list = this.skuPopGoods.sku_list;
        //console.log(sku_list);
        var result = [];
        sku_list.forEach(function (v, k) {
          let _attr = ':' + v['sku_attr_ids'] + ':';
          let _all_ids_in = true;
          for (k in ids) {
            if (_attr.indexOf(':' + ids[k] + ':') == -1) {
              _all_ids_in = false;
              break;
            }
          }
          if (_all_ids_in) {
            result.push(v);
          }
        });
        return result;
      },
      _filterAttrs(ids) {
        //console.log(ids);
        var products = this._filterProduct(ids);
        //console.log(products);
        //return false;
        var result = [];
        products.forEach(function (v, k) {
          //console.log(v);
          result = result.concat(v['sku_attr_ids'].split(':'));
        });
        //console.log(result);
        return result;
      }
    }
  }
</script>

<style scoped>
	/**
	 * sku选择器
	 */
	 .skuPopBox {
		 z-index: 999;
		padding:30px 20px;
		padding-bottom: 130px;
	}
	.skuPop-gcard-box {
		padding:20px 10px !important;
		background-color: #FFFFFF !important;
		border-bottom: 1px solid #eee;
	}
	.skuPop-gcard-box .van-card__thumb {
		height: 120px !important;
		width: 120px !important;
	}
	.skuPop-gthumb-box {
		height: 120px !important;
		width: 120px !important;
	}
	.sku-item-box {
		margin:0px 20px;
		padding-top:6px;
		padding-bottom: 30px;
		border-bottom: 1px solid #eee;
	}
	.sku-item-box .sku-item-name {
		color:#333;
		font-size:26px;
		line-height: 68px;
	}
	.sku-item-box .sku-item-value-box {
		padding:6rpx 0rpx;
	}
	.sku-item-box .sku-item {
		display: inline-block;
		padding:8px 8px;
		background-color: #efefef;
		border-bottom: #eee;
		color:#666;
		font-size:24px;
		border-radius: 4px;
		margin-right:20px;
		min-width: 60px;
		text-align: center;
	}
	.sku-item-box .sku-active {
		color:#FFFFFF;
		font-weight: bold;
		background-color: #ffbd01;
		border: 1px solid #fda608;
	}
	.sku-pop-btn-box {
		margin-bottom: 28px;
	}
	.sku-pop-btn-box .van-button {
		height: 84px;
		font-size: 32px;
	}
	.sku-active {
		color:#FFF000;
	}

</style>
